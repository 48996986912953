import { Container, createTheme, ThemeProvider } from '@mui/material';
import './App.css';
import Notification from './component/Notification';
import { Toaster } from 'react-hot-toast';


const theme = createTheme({
  typography: {
    fontFamily:'Archivo, sans-serif'
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
       <Toaster position='bottom-center' />
      <Container maxWidth="md">
        <Notification />
      </Container>
    </ThemeProvider>
  );
}

export default App;
